@media (max-width: 991px){
    *{
        user-select: none;
        -webkit-user-select: none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-overflow-scrolling: auto;
    }
    *::-webkit-scrollbar {
        width: 0px;
      }
}

@media(max-width:767px) {
    .external-link-wrapper .dashboard-box {
        width: 100%;
    }

    .external-link-inr {
        gap: 15px;
        padding: 10px 15px;
    }

    .external-link-content p {
        font-size: 14px;
    }

}


@media(max-width:475px) {
    .external-link-icon img {
        width: 30px;
    }

    .auth-header {
        padding: 30px 25px 0;
    }

    .auth-title p {
        line-height: 28px;
        font-size: 16px;
    }

    .auth-title h2, .heading-wrap h5:first-child  {
        font-size: 24px;
        line-height: 28px;
    }

    .auth-title {
        padding: 30px 25px;
    }

    .auth-header img {
        width: 210px;
    }
}