.transparent-btn {
    background: transparent;
    border: 0;
}

.icon-btn {
    padding: 0;
}

.menu-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid rgb(30 30 30 / 20%);
}

.menuBar-tabs,
.account-tabs {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.menuBar-tabs li,
.account-tabs li {
    flex: 1;
}

.menu-link,
.tab-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(30, 30, 30, 0.7);
    border-top: 4px solid transparent;
    text-align: center;
    height: 81px;
    transition: 300ms all;
}

.tab-link {
    border-top: 0;
    border-bottom: 4px solid transparent;
    padding: 10px 15px;
    height: 99px;
}

.menu-link.active-link,
.tab-link.active-tab {
    color: rgb(30, 30, 30);
    font-weight: 600;
    border-color: #1D1E20;
    padding: 13px 15px;
}

.menu-link img,
.tab-link img {
    opacity: 0.7;
}

.menu-link.active-link img,
.tab-link.active-tab img {
    opacity: 1;
}

.tab-link img {
    max-width: 30px;
}

.account-tabs-wrapper {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid rgb(30 30 30 / 20%);
}

.menuBar-scroll {
    max-height: calc(100dvh - 150px);
    overflow: auto;
    min-height: calc(100dvh - 150px);
}

.profile-page .menuBar-scroll {
    overflow: auto;
}

.bg-gradient {
    background: linear-gradient(179deg, #FFFFFF 0%, #F1F1F8 100%);
}

.auth-page .bg-gradient {
    min-height: 100dvh;
}

/* login page */

.auth-inr {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    position: relative;
    z-index: 9;
    overflow: auto;
}

.auth-header {
    padding: 85px 25px 0;
}

.auth-header img {
    width: 230px;
}

.auth-title {
    padding: 40px 25px;
}

.auth-title p {
    margin: 0;
    line-height: 33px;
    margin-bottom: 5px;
    color: rgb(29 30 32 / 70%);
    font-weight: 400;
    font-size: 18px;
}


.auth-title .auth-subtitle,
.heading-wrap h5+h5 {
    line-height: 21px;
    font-size: 14px;
    margin: 10px 0 0;
    font-weight: 400;
}

.auth-title h2,
.heading-wrap h5:first-child {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.75px;
    background: #C5EA34;
    padding: 7px 14px;
    border-radius: 10px;
    display: inline-block;
    line-height: 33px;
}

.auth-form-wrap {
    padding: 0 25px;
    padding-bottom: 25px;
}

.icon-input-container {
    position: relative;
}

.input-field {
    border: 1px solid #1D1E20;
    border-radius: 8px;
    padding: 10px;
    min-height: 50px;
    width: 100%;
    background: transparent;
    outline: none;
    font-size: 16px;
    letter-spacing: -0.5px;
    font-weight: 500;
    color: rgb(29 30 32 / 80%);
}

/* .profile-form .input-field {
    background: rgb(29 30 32 / 10%);
    border: 0;
    color: #000;
    font-size: 15px;
    padding: 10px 15px;
} */

.icon-input-container .input-field {
    padding-right: 40px;
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-field>label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
    letter-spacing: -0.5px;
    color: #1D1E20;
}

.form-field+.form-field {
    margin-top: 15px;
}

.link-field p {
    margin: 0;
    text-align: right;
}

.link-field p a {
    color: #000;
    font-size: 16px;
    font-weight: 500;
}

.custom-btn {
    min-height: 50px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 600;
    color: #1D1E20;
    background: #C6EA2A;
    border: 0;
    border-radius: 8px;
    width: 100%;
    text-transform: capitalize;
    overflow: hidden;
}

.custom-btn:hover {
    background: #1D1E20;
    color: #fff;
}

.btn-install {
    max-width: 110px;
    margin-top: 15px;
    background: #1D1E20;
    color: #fff;
    padding: 10px 12px;
    min-height: auto;
    font-size: 14px;
    display: block;
}

.btn-field {
    margin: 15px 0;
}

.text-field p {
    margin: 0;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}

.text-field p a {
    font-weight: 600;
    color: #000;
    text-decoration: underline;
}

/* register page */

.form-active {
    display: block;
}

.form-inactive {
    display: none;
}

.register-page .auth-header {
    padding: 30px 25px 0;
}

.register-wrap button.custom-btn,
.single-f-wrap button.custom-btn {
    max-width: 100px;
    min-height: 42px;
}

.pagination-wrapper.align-right {
    justify-content: end;
}

.register-wrap .radio-label {
    padding: 0;
}

.register-wrap .radio-field input[type=radio]+.radio-label:after {
    opacity: 0;
}

.register-wrap .radio-field-wrapper {
    gap: 5px 10px;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
}

.register-wrap .profile-form+h3 {
    margin: 25px 0 10px;
    font-size: 18px;
}

.register-wrap .radio-field {
    color: rgb(29 30 32 / 100%);
    background: transparent;
}

.register-wrap .radio-field input[type=radio]+.radio-label:before {
    border: 1px solid rgb(29 30 32 / 60%);
}

.register-wrap h3 {
    font-size: 20px;
}

.register-wrap .profile-form h4 {
    margin-bottom: 15px;
    font-size: 18px;
}

.register-wrap .form-field+h4 {
    margin-top: 20px;
}

.custom-btn.prev-btn {
    background: #1D1E20;
    color: #fff;
}

.custom-btn svg {
    width: 20px;
    height: 20px;
}

select.input-field {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.custom-select-wrapper {
    position: relative;
}

.custom-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%) rotate(45deg);
    pointer-events: none;
    border-bottom: 0.13em solid;
    border-right: 0.13em solid;
}

.step-indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 0 25px;
    position: relative;
}

.step-number {
    width: 50px;
    height: 50px;
    border: 1px solid rgb(29, 30, 32, 0.2);
    border-radius: 50%;
    background-color: #fff;
    color: #1D1E20;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    position: relative;
    z-index: 1;
}

.step-number.active {
    border-color: #C5EA34;
    background-color: #C5EA34;
}

.step-indicator::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50px;
    right: 50px;
    height: 1px;
    background-color: rgb(29, 30, 32, 0.2);
    z-index: 0;
}

.step-number:first-child::before {
    content: none;
}

.step-number:last-child::after {
    content: none;
}

.step-number.active::before,
.step-number.active::after {
    background-color: #C5EA34;
}

.drag-file-area {
    border: 2px dashed #1d1e20;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
}

.drag-file-area .image-preview {
    width: 50px;
    margin-bottom: 10px;
}

.dynamic-message {
    font-size: 16px;
    color: #1d1e20;
    margin-bottom: 20px;
    word-break: break-word;
}

.browse-files {
    display: inline-block;
    cursor: pointer;
    font-weight: bold;
}

.browse-files .default-file-input {
    display: none;
}

.browse-files-text {
    display: inline-block;
    padding: 10px 20px;
    background-color: #1d1e20;
    color: white;
    border-radius: 5px;
}

.file-input-wrapper {
    display: flex;
    align-items: center;
    position: relative;
}

.file-input {
    display: none;
    /* Hide the default file input */
}

.file-label {
    background-color: #1d1e20;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 10px;
}

.file-name {
    font-size: 14px;
    color: #555;
    font-style: italic;
}

.form-field h5 {
    font-size: 16px;
    margin-bottom: 15px;
    padding-top: 15px;
}

.form-field h5+h5 {
    font-size: 15px;
    font-weight: 400;
    padding: 0;
}

.register-wrap .form-field h5 {
    font-size: 20px;
}

.register-wrap .form-field h5+h5 {
    font-size: 16px;
}

.register-wrap .form-field h6 {
    font-size: 18px;
}

.register-wrap .form-field h6 {
    font-size: 18px;
    padding-top: 10px;
}

/* profile page */
.page-header {
    background: #1D1E20;
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 70px;
}

.profile-header {
    padding-bottom: 50px;
    position: relative;
    margin-bottom: 40px;
}

.page-title,
.page-title a {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    width: 100%;
}

.profile-form {
    border-radius: 15px;
    background: #fff;
    padding: 15px;
    border: 1px solid rgb(30 30 30 / 20%);
}

.profile-dtl {
    padding: 20px;
}

.profile-form .form-field.btn-field {
    margin-top: 30px;
}

.profile-form .form-field>label {
    font-size: 14px;
}

h3.inr-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #1D1E20;
    text-transform: capitalize;
}

.profile-form ul {
    display: flex;
    flex-direction: column;
}

.profile-form ul li {
    border-bottom: 1px solid rgb(29 30 32 / 10%);
    padding: 10px 0;
    flex-wrap: wrap;
    gap: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-form ul li label {
    font-size: 14px;
    font-weight: 500;
    max-width: 94px;
    min-width: 94px;
}

.shares-page ul.no-shares-list li label {
    min-width: 100%;
    max-width: 100%;
}

.profile-form ul li h4 {
    font-size: 14px;
    font-weight: 600;
    color: #1D1E20;
    width: calc(100% - 101px);
    /* word-break: break-all; */
}

.select-account ul {
    display: flex;
    gap: 6px;
    overflow: auto;
    margin: 10px 0 20px;
    flex-wrap: nowrap;
}

.shares-page .share-fade.no-shares {
    opacity: 0;
    transition: 300ms all;
}

.no-shares {
    opacity: 1;
}

.profile-form .no-shares-list label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 15px;
}

.profile-form .no-shares-list label img {
    max-width: 22px;
}

.no-shares p {
    font-size: 14px;
    margin: 0 0 15px;
}

.women-wealth h3 {
    font-size: 16px;
    margin-bottom: 5px;
}

.profile-form ul .anual-fee {
    padding: 0 28px 10px;
    font-weight: bold;
    font-size: 15px;
    display: block;
    width: 100%;
}

.list-account .radio-label {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    padding: 10px 12px;
    white-space: nowrap;
}

.list-account .radio-field input[type=radio]:checked+.radio-label:after {
    background: #C6EA2A;
}

.select-account .radio-field input[type=radio]:checked+.radio-label {
    font-weight: bold;
    color: #1D1E20;
}

.select-account .radio-field input[type=radio]+.radio-label:before {
    display: none;
}

.select-account .radio-field input[type=radio]+.radio-label:after {
    border-radius: 30px;
    background: #fafdf4;
}

.select-account span {
    position: relative;
    z-index: 1;
}

.profile-form+.profile-form {
    margin-top: 15px;
}

h4.status-label, h4 span.status-label {
    padding: 5px 10px;
    border-radius: 20px;
    width: auto !important;
    margin-right: auto;
}

h4.status-label.Paid, h4 span.status-label.Paid {
    background: #C5EA34;
}

h4.status-label.Over, h4 span.status-label.Over {
    background: #FF8E64;
}

h4.status-label.Due, h4 span.status-label.Due {
    background: #FF4E4E;
}

h4.status-label.Credit, h4 span.status-label.Credit {
    background: #76ff76;
}

.react-datepicker-wrapper {
    width: 100%;
}

.profile-form ul li:last-child {
    border: 0;
}

.inr-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-section {
    padding-top: 5px;
}

.custom-alert {
    padding: 10px;
    border: 1px solid rgb(30 30 30 / 13%);
    border-radius: 8px;
    background: rgb(198 234 42 / 6%);
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    margin: 0;
}

.custom-alert-1 {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    min-height: calc(100vh - 120px);
    justify-content: center;
    gap: 20px;
}


/* dashboard page */
.dashboard-wrapper {
    padding: 20px;
}

.dashboard-wrapper h3.greeting-text {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
}

.dashboard-listing {
    display: flex;
    flex-direction: column;
    gap: 14px;
    padding: 24px 0;
}

.list-box {
    padding: 24px 20px;
    border-radius: 15px;
}

.list-box:first-child {
    background: linear-gradient(90deg, #d75728 0%, #FF7F50 100%);
}

.list-box:nth-child(2) {
    background: linear-gradient(90deg, #8DA23B 0%, #C5EA34 100%);
}

.list-box:nth-child(3) {
    background: linear-gradient(90deg, #CCA61F 0%, #FFD027 100%);
}

.list-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.list-detail+.list-detail {
    padding-top: 10px;
    border-top: 1px solid rgb(30 30 30 / 20%);
    margin-top: 10px;
}

.list-detail p {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
    margin: 0;
    color: #1D1E20;
    text-transform: capitalize;
}

.list-detail h3 {
    font-size: 22px;
    font-weight: bold;
    color: #1D1E20;
}

.box-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.dashboard-box {
    width: calc(50% - 10px);
}

.dashboard-box img {
    width: 60px;
}

.dash-box-inr {
    background: #fff;
    padding: 20px 10px;
    border-radius: 10px;
    text-align: center;
    border: 1px solid rgb(30 30 30 / 20%);
}

.dash-box-inr p {
    color: #1D1E20;
    margin: 0;
    font-weight: 500;
    font-size: 16px;
}

.notes-wrapper {
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    margin-top: -5px;
    max-width: 70%;
    margin: 0 auto;
}

/* splash screen */

.splash-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 9999;
    transition: 0.5s ease-in;
}

.splash-screen img {
    width: 300px;
}

.splash-screen p {
    font-size: 14px;
    margin: 0;
    padding: 25px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 4px;
    justify-content: center;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    background: #fff;
}

.footer img {
    max-width: 14px;
}

label.badge {
    background: #C6EA2A;
    padding: 3px 6px;
    min-width: 40px;
    display: inline-block;
    text-align: center;
    border-radius: 50px;
}

/* forms page css */
.success-screen {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: calc(100dvh - 70px);
    min-height: calc(100dvh - 70px);
    text-align: center;
    padding: 25px;
    flex-direction: column;
}

.success-screen h2 {
    font-size: 30px;
    font-weight: bold;
}

.custom-alert-1 {
    font-size: 22px;
    font-weight: bold;
}

.success-screen p {
    color: #1D1E20;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin: 20px 0 0;
}

.forms-listing ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.forms-listing ul li {
    border: 1px solid rgb(29 30 32 / 20%);
    padding: 10px 20px;
    border-radius: 15px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
}

.forms-listing img {
    width: 10px;
}

.forms-listing h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.forms-listing h4 span {
    font-size: 14px;
    font-weight: 400;
    color: rgb(29 30 32 / 60%);
}

.success-screen .btn-field {
    margin-top: 50px;
    width: 100%;
}

.success-screen .btn-field a.custom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-form-wrapper {
    position: relative;
    padding-bottom: 0;
    /* Default padding */
    transition: padding-bottom 0.3s ease;
}

.single-form-wrapper.keyboard-open {
    padding-bottom: 0;
    /* Adjust or remove the bottom padding */
}

.single-form-wrapper .form-field>label {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
}

.radio-field-wrapper {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.radio-field {
    font-size: 13px;
    color: rgb(29 30 32 / 60%);
    font-weight: 500;
    background: #fff;
    overflow: hidden;
    line-height: 30px;
}

.radio-label {
    position: relative;
    padding: 7px 9px;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.radio-field input[type=radio]+.radio-label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid rgba(29, 30, 32, 0.3);
    width: calc(100% - 2px);
    border-radius: 8px;
    height: calc(100% - 2px);
    transition: 300ms all;
}

.radio-field input[type=radio]:checked+.radio-label:after {
    border: 1px solid #1d1e20;
}

.radio-field input[type=radio] {
    position: absolute;
    opacity: 0;
}

.radio-field input[type=radio]+.radio-label:before {
    content: "";
    background: rgb(29, 30, 32, 0.1);
    border-radius: 100%;
    border: 1px solid #b4b4b4;
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    margin-right: 5px;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
    box-shadow: inset 0 0 0 3px #fff;
}

.radio-field input[type=radio]:checked+.radio-label:before {
    background-color: #C6EA2A;
}

.radio-field input[type=radio]:focus+.radio-label:before {
    outline: none;
    border-color: #1D1E20;
}

.radio-field input[type=radio]+.radio-label:empty:before {
    margin-right: 0;
}

.single-form-wrapper .input-field {
    border: 1px solid rgba(29, 30, 32, 0.3);
    background: #fff;
    font-size: 15px;
    padding: 10px 15px;
}

.forms-wrapper.menuBar-scroll {
    max-height: calc(100dvh - 70px);
    min-height: calc(100dvh - 70px);
}

/* install popup css  */
.install-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    /* Apply blur effect */
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.install-popup {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    width: 90%;
    position: relative;
    text-align: -webkit-center;
}

.install-icon {
    margin-bottom: 20px;
}

.install-popup-overlay h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.auth-title .install-popup-overlay p {
    font-size: 14px;
    color: #666;
    line-height: 22px;
}

.install-popup-overlay .share-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    vertical-align: middle;
    /* Replace this with your SVG share icon */
}

.install-popup-overlay .share-wrap {
    border-top: 1px solid #00000038;
    padding-top: 10px;
}

.plus-icon-container {
    width: 100px;
    height: 100px;
    border-radius: 20px;
    border: 1px solid #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 5px;
    overflow: hidden;
    box-shadow: 0 0 10px #00000012;
}

.plus-icon-container img {
    width: 100%;
}


.profile-form ul li.card-child-has-list {
    display: block;
    border: 1px solid rgb(30 30 30 / 20%);
    border-radius: 15px;
    padding: 5px 15px;
    margin-top: 15px;
}

.menu-link img {
    width: 30px;
}

/* profile page */
.profile-head {
    margin-bottom: 20px;
    text-align: center;
}

.profile-head h3 {
    font-size: 20px;
}

.profile-icon {
    width: 70px;
    height: 70px;
    background: linear-gradient(90deg, #8DA23B 0%, #C5EA34 100%);
    border-radius: 50px;
    line-height: 70px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin: 0 auto 10px;
}

.profile-head span {
    font-size: 14px;
    color: rgb(29 30 32 / 70%);
}

.custom-profile-page .profile-list-inner li {
    padding: 15px 0;
}

.disclaimer-page p {
    font-size: 14px;
}

.full-dvh {
    height: calc(100dvh - 70px);
}
.flex-1{
    flex: 1;
}
.main-setting-page .profile-form ul li h4{
    font-size: 16px;
}
.main-setting-page .profile-form ul li{
    padding: 15px 0;
}

.external-link-wrapper {
    display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px;
        background: linear-gradient(90deg, #8DA23B 0%, #C5EA34 100%);
        border-radius: 10px;
}
.external-link-wrapper h3 {
    flex: 0 0 100%;
}
.external-link-content p a {
    font-weight: 600;
    color: #000;
    text-decoration: underline;
}
.external-link-inr {
    display: flex;
        align-items: center;
        gap: 20px;
        padding: 10px 20px;
        border-radius: 10px;
        border: 1px solid rgb(30 30 30 / 20%);
        height: 100%;
        background: #fff;
}

.external-link-icon img {
    width: 40px;
}
.external-link-content a img {
    width: auto;
}