@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
@import url(./main.css);
@import url(./responsive.css);

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    background: #fff;
    color: #1D1E20;
    font-size: 16px;
    letter-spacing: -0.5px;
    overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
button {
    text-decoration: none;
    transition: 300ms all;
    cursor: pointer;
}

button,
button:focus {
    outline: none;
}

* {
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

a,
button,
input[type="button"] {
    -webkit-user-select: none;
}

svg,
path {
    transition: 300ms all;
}

::-ms-input-placeholder {
    /* Edge 12-18 */
    color: rgb(29 30 32 / 80%);
}

::placeholder {
    color: rgb(29 30 32 / 80%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background-color: transparent;
}

.error {
    margin: 0;
    color: red;
    font-size: 14px;
    font-weight: 400;
}

.text-red {
    color: #FF0000;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.spinner-wrap .spinner {
    width: 45px; 
    height: 45px; 
    border: 5px solid rgba(0, 0, 0, 0.1); 
    border-top: 4px solid #000; 
    border-radius: 50%;
    animation: spin 1s linear infinite; 
}


.custom-btn .spinner-wrap {
    display: flex;
    justify-content: center;
}

.custom-btn .spinner-wrap .spinner {
    width: 30px;
    height: 30px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid #000;
}

.custom-btn:hover .spinner-wrap .spinner {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
}

.register-page .spinner-wrap {
    min-height: 100dvh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-form-wrapper .spinner-wrap {
    min-height: calc(100dvh - 120px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fade-enter {
    opacity: 0;
    transition: opacity 0.5s ease-in;
}

.fade-enter-active {
    opacity: 1;
}


@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}